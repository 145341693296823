// listing page for each event type, e.g. /the-meeting-room/

import React from 'react'
import PropTypes from 'prop-types'

import EventsListing from '../components/EventsListing'
import EventsNav from '../components/EventsNav'
import TemplateWrapper from '../components/Layout'

import { graphql } from 'gatsby'

const Events = ({ pageContext, data }) => {
  return (
    <TemplateWrapper>
      <div className="event-listing">
        <EventsNav items={data.eventsnav.group} />

        {
          //<div className="event-listing-title">{ event }</div>
        }

        <EventsListing
          data={data.allMdx}
          ongoing={data.ongoing}
          pageContext={pageContext}
        />
      </div>
    </TemplateWrapper>
  )
}

Events.propTypes = {
  pageContext: PropTypes.shape({
    event: PropTypes.string.isRequired,
  }),
  data: PropTypes.shape({
    allMdx: PropTypes.shape({
      totalCount: PropTypes.number.isRequired,
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            fields: PropTypes.shape({
              slug: PropTypes.string.isRequired,
            }),
            frontmatter: PropTypes.shape({
              title: PropTypes.string.isRequired,
            }),
          }),
        }).isRequired
      ),
    }),
  }),
}

export default Events

export const pageQuery = graphql`
  query EventPage($event: String) {
    allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          events: { in: [$event] }
          eventType: { ongoing: { ne: true } }
        }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            customDate
            eventType {
              assemblies
            }
            time
            showOnHomepage
            galleryImages {
              publicURL
              childImageSharp {
                fixed(width: 780, quality: 70) {
                  ...GatsbyImageSharpFixed_noBase64
                }
              }
            }
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }
    ongoing: allMdx(
      limit: 2000
      sort: { fields: [frontmatter___date], order: DESC }
      filter: {
        frontmatter: {
          eventType: { ongoing: { eq: true } }
          events: { in: [$event] }
        }
        fileAbsolutePath: { regex: "/events/" }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          id
          frontmatter {
            title
            subtitle
            date
            customDate
            time
            eventType {
              assemblies
            }
            showOnHomepage
            primaryImage {
              publicURL
              childImageSharp {
                fluid(maxWidth: 600) {
                  ...GatsbyImageSharpFluid_noBase64
                }
              }
            }
            teaser
          }
          excerpt
        }
      }
    }

    eventsnav: allMdx(limit: 2000) {
      group(field: frontmatter___events) {
        fieldValue
        totalCount
      }
    }
  }
`
